<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>{{ $t("AUTH.FORGOT.TITLE") }}</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div
            v-if="isSomethingWrong"
            role="alert"
            class="alert fade alert-danger show d-flex flex-column"
            v-html="messageError"
          ></div>

          <b-form-group id="login" label="" label-for="login">
            <b-form-input
              id="email"
              name="email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              placeholder="Email"
              aria-describedby="input-1-live-feedback"
              autocomplete="email"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a href="#/login" class="kt-link kt-login__link-forgot">
              {{ $t("AUTH.LOGIN.BUTTON") }}
            </a>
            <b-button
              :disabled="!!disabled"
              type="submit"
              size="sm"
              class="btn btn-primary btn-elevate kt-login__btn-primary px-1"
              style="width: 180px"
            >
              <strong>{{ $t("AUTH.FORGOT.BUTTON") }}</strong>
              <b-spinner
                v-if="isLoading"
                small
                type="grow"
                variant="light"
                class="ml-auto"
              ></b-spinner>
            </b-button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PASSWORD_FORGOT, LOGOUT } from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "ForgotPassword",

  mixins: [validationMixin],

  data() {
    return {
      // Remove this dummy login info
      form: {
        email: ""
      },
      disabled: true,
      errorMessage: "",
      isSomethingWrong: false,
      isLoading: false
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },

  mounted() {
    this.$store.dispatch(LOGOUT);
  },

  watch: {
    form: {
      handler() {
        this.disabled = !this.validateState("email");
      },
      deep: true
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.form.$touch();
      this.isSomethingWrong = false;
      this.errorMessage = "";
      this.isLoading = true;
      this.disabled = true;

      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      this.$store.dispatch(LOGOUT);

      this.$store
        .dispatch(PASSWORD_FORGOT, { email })
        .then(() => {
          this.disabled = true;
          this.$bvToast.toast(
            "An email has been sent to you with instructions on how to reset your password.",
            {
              title: "Check your inbox",
              variant: "success",
              solid: true
            }
          );

          setTimeout(() => this.$router.push({ name: "login" }), 2000);
        })
        .catch(error => {
          const textError =
            error?.data?.message || error?.data || "Something was wrong.";

          this.isSomethingWrong = true;
          this.messageError = textError;
          this.disabled = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style scoped>
button:disabled {
  color: #ffffff;
}
button:hover {
  color: #e4e4e4;
}
</style>
